<ng-container *ngIf="userObs$ | async as user; else loadingProfile">
    <div class="center">
        <app-profile-overview
            [user]="user">
        </app-profile-overview>

        <div class="my-5">
            <ng-container *ngIf="(locationsObs$ | async) as locations">
                <ng-container *ngIf="(reservationsObs$ | async) as reservations">
                    <app-profile-reservations
                        [user]="user"
                        [locations]="locations"
                        [reservations]="reservations">
                    </app-profile-reservations>
                </ng-container>
            </ng-container>
        </div>

        <app-profile-penalties 
            [user]="user">
        </app-profile-penalties>
    </div>
</ng-container>
<ng-template #loadingProfile>
    <div class="messages messages--info">
        <i class="icon-info"></i>
        {{ 'profile.overview.loading' | translate }}
    </div>
</ng-template>