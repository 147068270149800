<ng-container>
    <div class="d-flex align-items-center justify-content-between mb-4">
        <h3 class="m-0">
            {{ 'profile.reservations.locations.title' | translate }}
        </h3>
    </div>

    <!-- List -->
    <app-management-table
        [stateless]="false"
        [data]="reservations"
        [actions]="getTableActions()"
        [mapper]="getTableMapper()">
    </app-management-table>

    <!-- Modal view to ask whether the user is sure to delete the reservation -->
    <app-modal #remove>
        <!-- Header -->
        <div modal-header>
            <h2>
                {{'management.reservations.location.deleteModal.title' | translate}}
            </h2>
        </div>

        <!-- Body -->
        <ng-container *ngIf="user" modal-body>
            <ng-container *ngIf="selectedSub$ | async as reservation">
                <p>
                    {{'management.reservations.location.deleteModal.body' | translate}}
                </p>
                <ul>
                    <!-- If ProfileReservationsComponent is used in the UserDetailsManagementComponent, you also want the name
                       of the user of which you are deleting the LocationReservation -->
                    <li>
                        <b>{{('profile.reservations.locations.table.header.user' |translate) + ': '}}</b> {{user.firstName + ' ' + user.lastName}}
                    </li>
                    <li>
                        <b>{{('profile.reservations.locations.table.header.reservationDate' |translate) + ': '}}</b> {{ reservation.timeslot.timeslotDate.format("DD/MM/YYYY") }}
                    </li>
                    <li>
                        <b>{{('profile.reservations.locations.table.header.beginHour' | translate) + ':'}}</b> {{ reservation.timeslot.getStartMoment().format("HH:mm") }}</li>
                </ul>
            </ng-container>
        </ng-container>

        <!-- Footer -->
        <div modal-footer>
            <div *ngIf="selectedSub$ | async as reservation">
                <button type="button" class="button button-primary" (click)="storeDelete(reservation)">
                    {{'management.reservations.location.deleteModal.yesButton' | translate}}
                </button>
            </div>
            <div>
                <button type="button" class="button button-secondary" (click)="remove.close()">
                    {{'management.reservations.location.deleteModal.noButton' | translate}}
                </button>
            </div>
        </div>

        <div modal-message-footer>
            <div class="messages messages--info" *ngIf="(isSuccess | async) === undefined">
                {{'general.waitingForServer' | translate}}
            </div>

            <div class="messages messages--success" *ngIf="(isSuccess | async) === true">
                {{'management.reservations.location.deleteModal.success' | translate}}
            </div>

            <div class="messages messages--error" *ngIf="(isSuccess | async) === false">
                {{'management.reservations.location.deleteModal.error' | translate}}
            </div>
        </div>
    </app-modal>
</ng-container>
