<ng-container *ngIf="itemObs$ | async as item;">
    <div id="faq-item-layout">
        <div class="faq-item-header">
            <div class="faq-item-title">
                <h2>{{ item.title.translations[locale] }}</h2>
            </div>
            <div class="tag mb-3">
                {{ item.category.name.translations[locale] }}
            </div>
        </div>
        <hr>
        <app-message>
            Hellaur
        </app-message>
        <ng-container>
            <div class="faq-item-content" [innerHTML]="item.content.translations[locale]"></div>
        </ng-container>
    </div>
</ng-container>
