<ng-container>
    <!-- Title -->
    <div class="d-flex align-items-center justify-content-between mb-4">
        <h3 class="m-0">
            {{ 'penalties.management.subheader' | translate }} {{ currentUser.firstName }} {{ currentUser.lastName }}
        </h3>
        <button class="button button-primary" (click)="prepareAdd()">
            {{ 'penalties.management.addButton' | translate}}
        </button>
    </div>

    <!-- List -->
    <app-management-table
        [stateless]="false"
        [data]="penalties?.penalties"
        [actions]="getTableActions()"
        [mapper]="getTableMapper()">
    </app-management-table>

    <!-- Add penalty form -->
    <app-modal #modify>
        <h2 modal-header>
            {{ 'penalties.management.addButton' | translate }}
        </h2>
        <div modal-body>
            <form [formGroup]="formGroup">
                <!-- points -->
                <div class="row">
                    <div class="col">
                        <label class="control-label">
                            {{ "penalties.management.points" | translate }}
                            <input type="number" class="form-control" min="0" formControlName="points" />
                        </label>
                    </div>
                </div>

                <!-- Description -->
                <div class="row">
                    <div class="col">
                        <label class="control-label">
                            {{ "penalties.management.description" | translate }}
                            <input type="text" class="form-control" formControlName="description" />
                        </label>
                    </div>
                </div>
            </form>
        </div>
        <!-- Modal footer -->
        <div modal-footer>
            <button type="submit" class="button button-primary" [disabled]="!formGroup.valid" (click)="storeAdd()">
                {{ 'general.buttons.add' | translate}}
            </button>
            <button type="button" class="button button-secondary" (click)="closeModal(modify)">
                {{'general.buttons.close' | translate}}
            </button>
        </div>
    </app-modal>
</ng-container>
