<ng-container>
    <!-- Page title -->
    <app-card>
        <div class="row gap-2 gap-md-0">
            <div class="col-md d-flex justify-content-center">
                <img [src]="logoSource" class="object-fit-contain" alt="Institution">
            </div>
            <div class="col-md-8 d-flex flex-column justify-content-center">
                <h4>
                    {{'profile.overview.title' | translate}}
                </h4>
                <div class="d-flex align-items-center gap-2">
                    <i class="icon-user font-xl" style="font-size: 24px"></i>
                    <input type="text" class="form-control" [value]="user.getFullName()" disabled> 
                </div>
                <div class="d-flex align-items-center gap-2 mt-3">
                    <i class="icon-envelope font-xl" style="font-size: 24px"></i>
                    <input type="text" class="form-control" [value]="user.mail" disabled> 
                </div>
            </div>
        </div>
    </app-card>
    <app-card [title]="'profile.overview.qrcode-title' | translate">
        <app-message>
            {{'profile.overview.qrcode' | translate}}
        </app-message>
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
            <app-qrcode
                [user]="user">
            </app-qrcode>
            <app-barcode
                [user]="user">
            </app-barcode>
        </div>
    </app-card>
</ng-container>
